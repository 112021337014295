import { Link } from '@remix-run/react'
import { Stack, H2, P } from '~/components-v2'

export const FooterDemoSection = () => {
  return (
    <section className="footer-demo-section">
      <Stack className="footer-demo-text" gap={2}>
        <H2 fontSize="xxxl" fontWeight={700}>
          Get a live demo
        </H2>
        <P>See how Omni can help your team get more from your data.</P>
        <Stack gap={3} padding="6,0">
          <Link className="call-to-action size-md primary" to="/request-demo">
            Request Demo
          </Link>
        </Stack>
      </Stack>
      <div className="footer-demo-imagery">
        <img src="/images/pages/home/footer-workbook-view.webp" />
        <svg
          width="66"
          height="66"
          viewBox="0 0 66 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="outline-blobby"
        >
          <circle
            cx="29.9998"
            cy="29.9998"
            r="31.2498"
            transform="matrix(1 0 -1.81459e-05 1 3.00146 3)"
            fill="url(#paint0_linear_6784_368249)"
            stroke="#480B38"
            strokeWidth="2.50003"
          />
          <path
            d="M41.437 32.0625C41.437 32.9554 39.8107 35.1875 36.7495 35.1875C33.5608 35.1875 32.0621 33.1786 32.0621 32.2113"
            stroke="#480B38"
            strokeWidth="3.12503"
            strokeMiterlimit="1.33344"
            strokeLinecap="round"
          />
          <ellipse
            cx="3.80432"
            cy="3.80432"
            rx="3.80432"
            ry="3.80432"
            transform="matrix(-1 8.8372e-08 8.8372e-08 1 55.5 27.375)"
            fill="#480B38"
          />
          <ellipse
            cx="3.80432"
            cy="3.80432"
            rx="3.80432"
            ry="3.80432"
            transform="matrix(-1 8.8372e-08 8.8372e-08 1 25.8096 27.375)"
            fill="#480B38"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6784_368249"
              x1="4.47601"
              y1="8.13992"
              x2="58.2446"
              y2="27.1246"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F3D4A2" />
              <stop offset="0.427083" stopColor="#FF7AA2" />
              <stop offset="0.977308" stopColor="#BCA2F3" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  )
}
