import Badge1 from './badge1.svg?react'
import Badge2 from './badge2.svg?react'
import Badge3 from './badge3.svg?react'

export const G2Badges = () => (
  <section className="g2-badges grid cols3">
    <Badge1 />
    <Badge2 />
    <Badge3 />
  </section>
)
